import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import {
  FEATURE_AWS_RUM,
  AwsRumClient,
  getStage,
} from '@amzn/alkimia-react-components';

import App from './App';
import './index.scss';

const root = createRoot(document.getElementById('root') as HTMLElement);
if (FEATURE_AWS_RUM[getStage()]) {
  AwsRumClient.initializeRumClient();
}
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
);
