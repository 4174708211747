import { AwsResourceConfiguration } from '@amzn/alkimia-model';
import {
  Alert,
  Box,
  Button,
  Container,
  FormField,
  Header,
  Input,
  Link,
  ProgressBar,
  Select,
  SpaceBetween,
  Spinner,
  TextContent,
  Toggle,
} from '@amzn/awsui-components-react-v3';
import {
  OptionDefinition,
} from '@amzn/awsui-components-react-v3/polaris/internal/components/option/interfaces';
import { CognitoToken } from 'amazon-cognito-auth-ts';
import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import {
  getStage,
  DeploymentStage,
  LIST_OF_REGIONS,
} from '@amzn/alkimia-react-components';

import { getAwsAccountId } from '../../api/calls/getAwsAccountId';
import { getAwsAccountName } from '../../api/calls/getAwsAccountName';
import { listAwsAccounts } from '../../api/calls/listAwsAccounts';
import { scanAwsAccount } from '../../api/calls/scanAwsAccount';

type AlkimiaHomeProps = {
  accountLabel: string,
  setAccountLabel: React.Dispatch<React.SetStateAction<string>>,
  setRegionID: React.Dispatch<React.SetStateAction<string>>,
  setErrorMessages: React.Dispatch<React.SetStateAction<string[]>>,
  setListResources: React.Dispatch<React.SetStateAction<AwsResourceConfiguration[]>>,
  token: CognitoToken,
}

type SelectedOption = {
  label: string;
  value: string;
}

function AlkimiaHomeComponent({
  token,
  accountLabel,
  setAccountLabel,
  setRegionID,
  setErrorMessages,
  setListResources,
}: AlkimiaHomeProps) {
  const [selectedAccountName, setSelectedAccountName] = useState('');
  const [region, setRegion] = useState('us-east-1');
  const [options, setOptions] = useState<SelectedOption[]>([{
    label: '',
    value: '',
  }]);
  const [loadingAccounts, setLoadingAccounts] = useState(true);
  const [regionOptions, setregionOptions] = useState<SelectedOption[]>(LIST_OF_REGIONS);
  const [selectedOption, setSelectedOption] = useState<SelectedOption>();
  const [selectedRegion, setSelectedRegion] = useState<SelectedOption>({
    label: 'us-east-1 (N.Virginia)',
    value: 'us-east-1',
  });
  const [isBlocked, setIsBlocked] = useState(false);
  const [blockMsg, setBlockMsg] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [toggleAccountInput, setToggleAccountInput] = useState(false);
  const [typedAccountId, setTypedAccountId] = useState('');
  const [percentScanned, setPercentScanned] = useState(0);
  const [progressDescription, setProgressDescription] = useState('');
  const progressIntervalId = useRef<ReturnType<typeof setInterval>>();
  const history = useHistory();
  useEffect(() => {
    const getAccounts = async () => {
      try {
        const options = await listAwsAccounts(token)
          .then((accountList) => (
            (accountList.map((account) => (
              {
                label: `${account.name}`,
                value: `${account.name}`,
              }
            ))
            )));
        setOptions(options);
        setLoadingAccounts(false);
      } catch (e: any) {
        setIsBlocked(true);
        setBlockMsg('Can not fetch list of AWS accounts. Please refresh and try again.');
      }
    };
    getAccounts();
  }, []);

  useEffect(() => () => {
    clearInterval(progressIntervalId.current);
  }, []);

  const startProgress = useCallback(() => {
    progressIntervalId.current = setInterval(() => {
      // Pseudo logarithmic progress
      // 50% at 30seconds, 80% at 60seconds, 100% at 90 seconds, then restart at 50
      setPercentScanned((prev) => {
        let newPercent = 0;
        if (prev < 50) {
          newPercent = prev + 1.66;
        } else if (prev < 80) {
          newPercent = prev + 1;
        } else if (prev < 98) {
          newPercent = prev + 0.66;
        } else {
          newPercent = 50;
        }
        return newPercent;
      });
    }, 1000);
  }, [progressIntervalId]);

  return (
    <div id="alkimia-home-container">
      {getStage() !== DeploymentStage.PROD
        && (
          <Alert type="warning">
            <div style={{
              display: 'flex',
              alignItems: 'center',
            }}
            >
              <div style={{ flex: 1 }}>
                <b>
                  This environment is for development and integration. Please use the stable
                  production website at
                </b>
                {' '}
                <a href="https://prod.alkimia.tools.amazon.dev/">prod.alkimia.tools.amazon.dev</a>
              </div>
              <div>
                <Button
                  ariaLabel="Feedback"
                  iconAlign="right"
                  href="https://sim.amazon.com/issues/create?template=da0d26a2-abe4-439e-9f67-324ea1b57b01"
                  variant="link"
                  iconName="external"
                  target="_blank"
                >
                  Provide Feedback
                </Button>
              </div>
            </div>
          </Alert>
        )}
      <Box fontWeight="normal">
        Region Expansion Accelerators (REXA)
      </Box>
      <div style={{
        display: 'flex',
        alignItems: 'center',
      }}
      >
        <div style={{ flex: 1 }}>
          <Box variant="h1" fontWeight="bold" fontSize="display-l">
            Alkimia
          </Box>
        </div>
        {getStage() === DeploymentStage.PROD && (
          <div>
            <Button
              ariaLabel="Feedback"
              iconAlign="right"
              href="https://sim.amazon.com/issues/create?template=da0d26a2-abe4-439e-9f67-324ea1b57b01"
              variant="link"
              iconName="external"
              target="_blank"
            >
              Provide Feedback
            </Button>
          </div>
        )}
      </div>
      <Box variant="p" fontWeight="normal">
        An AWS resource exporter that discover the resources in an AWS account and generate the
        Cloud Development Kit (CDK) infrastructure-as-code (IaC) assets that describe them.
      </Box>
      <SpaceBetween size="s">
        <Container
          header={(
            <Header
              variant="h2"
              description=""
            >
              Get Started
            </Header>
          )}
        >
          <SpaceBetween size="s">
            <TextContent>
              Please first grant Alkimia access manually following instructions in below link,
              then select the AWS account to get started.
            </TextContent>
            <Link
              external
              externalIconAriaLabel="Opens in a new tab"
              href="https://w.amazon.com/bin/view/Alkimia/GrantAlkimiaAccessManually"
            >
              Grant Alkimia Access
            </Link>
            <FormField label="">
              <Toggle
                onChange={({ detail }) => setToggleAccountInput(detail.checked)}
                checked={toggleAccountInput}
              >
                Type Account ID manually
              </Toggle>
              <SpaceBetween direction="horizontal" size="s">
                <div id="select-account">
                  {toggleAccountInput === true
                    ? (
                      <Input
                        onChange={({ detail }) => {
                          setTypedAccountId(detail.value);
                        }}
                        value={typedAccountId}
                        placeholder="Type AWS account ID"
                      />
                    )
                    : (
                      <Select
                        selectedOption={selectedOption as OptionDefinition}
                        onChange={({ detail: { selectedOption } }) => {
                          setSelectedOption(selectedOption as SelectedOption);
                          setSelectedAccountName(selectedOption.value as string);
                        }}
                        options={options}
                        placeholder="Select the AWS account from which to export resources to CDK"
                        filteringType="auto"
                        disabled={loadingAccounts}
                      />
                    )}
                </div>
                <div id="select-region">
                  <Select
                    selectedOption={selectedRegion as OptionDefinition}
                    onChange={({ detail: { selectedOption } }) => {
                      setSelectedRegion(selectedOption as SelectedOption);
                      setRegion(selectedOption.value as string);
                      setRegionID(selectedOption.value as string);
                    }}
                    options={regionOptions}
                    filteringType="auto"
                  />
                </div>
                <Button
                  disabled={isProcessing}
                  variant="primary"
                  onClick={async (event) => {
                    setIsProcessing(true);
                    setIsBlocked(false);
                    event.preventDefault();
                    startProgress();
                    setProgressDescription('Retrieving account details');
                    let blocked = false;
                    let blockedMsg = '';
                    try {
                      let accountId = '';
                      if (toggleAccountInput) {
                        try {
                          const typedAccountName = await getAwsAccountName(token, typedAccountId);
                          typedAccountName.length > 0 ? setAccountLabel(`${typedAccountId}:${typedAccountName}`)
                            : setAccountLabel(`${typedAccountId}`);
                          accountId = typedAccountId;
                        } catch (e: any) {
                          blocked = true;
                          blockedMsg = `${e}`;
                        }
                      } else {
                        const selectedAccountId = await getAwsAccountId(token, selectedAccountName);
                        setAccountLabel(`${selectedAccountId}:${selectedAccountName}`);
                        accountId = selectedAccountId;
                      }
                      setProgressDescription('Scanning account resources');
                      const {
                        services,
                        messages,
                      } = await scanAwsAccount(token, accountId, region);
                      setListResources(services);
                      setErrorMessages(messages);
                    } catch (e: any) {
                      if (!blocked) {
                        blocked = true;
                        blockedMsg = `Can not fetch information of the AWS account. Please follow User Guide to grant Alkimia access first.${toggleAccountInput ? ' And make sure the account exists.' : ''}`;
                      }
                    }
                    setIsProcessing(false);
                    setPercentScanned(0);
                    if (blocked) {
                      setIsBlocked(true);
                      setBlockMsg(blockedMsg);
                      clearInterval(progressIntervalId.current);
                    } else {
                      history.push('/resource-selection');
                    }
                  }}
                >
                  Start
                </Button>
                <div data-testid="spinner">
                  {loadingAccounts === true
                    ? <Spinner size="normal" /> : ''}
                </div>
              </SpaceBetween>
              <div className="pad-t-10" data-testid="progress-bar">
                {isProcessing === true
                  ? <ProgressBar
                  value={percentScanned}
                  additionalInfo="Scans may take up to 15 minutes for accounts with thousands of resources"
                  description={progressDescription}
                  label={`Scanning account ${accountLabel}`}
                /> : ''}
              </div>
            </FormField>
            <Alert visible={isBlocked} type="error">
              {`${blockMsg}`}
            </Alert>
          </SpaceBetween>
        </Container>
        <Container
          header={(
            <Header variant="h2" description="Watch the demo video and follow User Guide.">
              How Alkimia works
            </Header>
          )}
        >
          <SpaceBetween direction="vertical" size="s">
            <iframe
              title="Alkimia demo"
              src="https://broadcast.amazon.com/embed/669725"
              width="525"
              height="300"
              allowFullScreen
            />
            <SpaceBetween direction="horizontal" size="s">
              <Link
                external
                externalIconAriaLabel="Opens in a new tab"
                href="https://w.amazon.com/bin/view/Alkimia/UserGuide/"
              >
                User Guide
              </Link>
            </SpaceBetween>
          </SpaceBetween>
        </Container>
      </SpaceBetween>
    </div>
  );
}

export const AlkimiaHome = AlkimiaHomeComponent;
