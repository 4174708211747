import { AlkimiaOptions, AwsGenerationOutput, AwsResourceConfiguration, ConstructType, OutputFormat } from '@amzn/alkimia-model';
import { Spinner } from '@amzn/awsui-components-react';
import { AppLayout, HelpPanel, Icon } from '@amzn/awsui-components-react-v3';
import { CognitoAuth } from 'amazon-cognito-auth-ts';
import React, { ReactElement, useEffect, useState } from 'react';
import { Route, Switch } from 'react-router';
import { AlkimiaResourceSelection, SelectionPageHelp,
  COGNITO_AUTH_CLIENT,
  getStage,
  CALL_BACK_URL,
} from '@amzn/alkimia-react-components';

import './app.scss';
import { AlkimiaHome } from './components/AlkimiaHome';
import { HomePageHelp } from './components/AlkimiaHome/HomeHelp';
import { AlkimiaOutput } from './components/AlkimiaOutput';
import { OutputPageHelp } from './components/AlkimiaOutput/OutputHelp';

import ErrorBoundary from './components/ErrorBoundary';

const App = (): ReactElement => {
  const [listResources, setListResources] = useState<AwsResourceConfiguration[]>([
    {
      resourceKey: '',
      sourceType: 'Config',
      configuration: {},
      ids: {},
      resourceType: '',
      sourceAccountId: '',
      sourceRegion: '',
      supportedConstructType: 'L1',
      relevant: true,
      downStreamDependencies: [],
      upStreamDependencies: [],
    },
  ]);

  const [awsGenerationOutput, setAwsGenerationOutput] = useState<AwsGenerationOutput>({
    awsAccountId: '',
    awsRegion: '',
    targetAwsAccountID: '',
    targetAwsRegion: '',
    cdkConstructType: ConstructType.DEFAULT,
    outputFormat: OutputFormat.JSON,
    outputFiles: [],
    messages: [],
  });
  const [accountLabel, setAccountLabel] = useState('');
  const [regionID, setRegionID] = useState('us-east-1');
  const [isProcessing, setIsProcessing] = useState(true);
  const [token, setAuthToken] = useState<any>(null);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const [isToolsOpen, setIsToolsOpen] = useState<boolean>(false);
  // the feature flags in Alkimia UI are hard coded to true as they are released in production. They are added as replicator wants to disable those features
  const [alkimiaOptions, setAlkimiaOptions] = useState<AlkimiaOptions>({ featureFlags: { IAC_COVERAGE_ANALYSIS: 'true', VERIFIED_SERVICES_TOGGLE: 'true' } });

  useEffect(() => {
    console.log('authenticating...');
    const auth = new CognitoAuth(COGNITO_AUTH_CLIENT);
    let authFields: any;
    let authToken: any;

    function authSuccesHandler(auth: CognitoAuth) {
      console.log('Running authSuccesHandler()');
      const sessionToken = auth
        .getSignInUserSession()
        .getIdToken();
      authFields = sessionToken.decodePayload();
      if (sessionToken.getJwtToken()) {
        setAuthToken(sessionToken.getJwtToken());
      }
    }

    function authFailureHandler(auth: CognitoAuth) {
      console.log('Running authFailureHandler()');
      authFields = auth
        .getSignInUserSession()
        .getIdToken()
        .decodePayload();
      authToken = {};
    }

    const startCognitoWorkflow = (
      auth: CognitoAuth,
      onLoginSuccess: (auth: CognitoAuth) => void,
      onLoginFailure: (auth: CognitoAuth) => void,
    ) => {
      auth.useCodeGrantFlow();

      // Create handlers for Auth calls
      auth.userhandler = {
        onFailure: (error) => {
          console.log(`Failed to authenticate user. error = ${JSON.stringify(error)}`);
          onLoginFailure(auth);
        },
        onSuccess: (result) => {
          console.log('Successfully authenticated user');
          onLoginSuccess(auth);
        },
      };

      const { href } = window.location;

      // Set current user Session
      const session = auth.getSignInUserSession();

      if (session.isValid()) {
        console.log('Session Valid');
      } else if (href.indexOf('?') > 0) {
        console.log('Parsing session');
        // This is required because Cognito needs to get the authentication result from the query string
        // The parsing is done asynchronously, and the result will be passed to the `auth.userHandler`.
        auth.parseCognitoWebResponse(href);

        // Set href back to main domain without refreshing page, this is needed otherewise if users refresh the page
        // with the old Code Grant, it will fail authentication because it will send an invalid Code Grant
        window.history.pushState('', '', CALL_BACK_URL[getStage()]);
      } else {
        // Cognito SDK will handle session refresh / authentication.
        console.log('Session refresh');
        // This is required because without this users have to refresh the page twice to clear cached tokens
        auth.clearCachedTokensScopes();
        auth.getSession();
      }
    };

    startCognitoWorkflow(auth, authSuccesHandler, authFailureHandler);
  }, []);

  useEffect(() => {
    if (token) setIsProcessing(false);
  }, [token]);

  return (
    isProcessing
      ? <Spinner size="normal" />
      : (
        <Route path="/">
          <AppLayout
            toolsOpen={isToolsOpen}
            onToolsChange={(change) => {
              setIsToolsOpen(change.detail.open);
            }}
            tools={(
              <HelpPanel
                footer={(
                  <div>
                    <h3>
                      More
                      {' '}
                      <Icon name="external" />
                    </h3>
                    <ul>
                      <li>
                        <a href="https://w.amazon.com/bin/view/Alkimia/">Alkimia Wiki</a>
                      </li>
                      <li>
                        <a
                          href="https://sim.amazon.com/issues/create?template=da0d26a2-abe4-439e-9f67-324ea1b57b01"
                        >
                          Report an Issue or Request new Feature
                        </a>
                      </li>
                    </ul>
                  </div>
                )}
                header={<h2>Help Panel</h2>}
              >
                <div>
                  <Switch>
                    <Route exact path="/">
                      <HomePageHelp />
                    </Route>
                    <Route path="/output">
                      <OutputPageHelp />
                    </Route>
                    <Route path="/resource-selection">
                      <SelectionPageHelp />
                    </Route>
                  </Switch>
                </div>
              </HelpPanel>
            )}
            navigationHide
            content={(
              <ErrorBoundary login="">
                <Switch>
                  <Route exact path="/">
                    <AlkimiaHome
                      token={token}
                      accountLabel={accountLabel}
                      setAccountLabel={setAccountLabel}
                      setRegionID={setRegionID}
                      setErrorMessages={setErrorMessages}
                      setListResources={setListResources}
                    />
                  </Route>
                  <Route path="/output">
                    <AlkimiaOutput
                      accountLabel={accountLabel}
                      awsRegion={regionID}
                      awsGenerationOutput={awsGenerationOutput}
                    />
                  </Route>
                  <Route path="/resource-selection">
                    <AlkimiaResourceSelection
                      token={token}
                      accountLabel={accountLabel}
                      regionID={regionID}
                      listResources={listResources}
                      errorMessages={errorMessages}
                      alkimiaOptions={alkimiaOptions}
                      setAwsGenerationOutput={setAwsGenerationOutput}
                      setIsToolsOpen={setIsToolsOpen}
                    />
                  </Route>
                </Switch>
              </ErrorBoundary>
            )}
          />
        </Route>
      )
  );
};

export default App;
